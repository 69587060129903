:root{
  --primary-color: #8700f5;
  --navbar: #006747;
  --clr-red: #ee0014;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#root {
  margin-bottom: 1em;

}
body{
  background-color: rgb(243, 244, 250);
  font-family: Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
  font-size: 1rem;
}
a{
  text-decoration: none;
}
label {
  font-weight: 400 !important;
  
}
.create-form {
  width: 50rem;
}

.youtube-embed {
  border: none;
  max-width: 100%;
}

.btn{
  border: none;
  outline: none;
  padding: 1em 2em;
  border-radius: 5px;
  background-color: #fff;
  color: rgb(11, 26, 152);
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 3px 5px #cfcfcf;
  display: inline-block;
  letter-spacing: 1px;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}
label.btn{
  display: inline-block;
  width: fit-content;
  max-width: auto;
  padding: .5em 1em;
  font-size: .9em;
  font-weight: normal;
}
.btn:hover{
  box-shadow: 0 1px 2px #cfcfcf;
}

.multi-option-image.create-form {
  max-width: 15rem;
}

.multi-option-image.add-field {
  max-width: 10rem;
}

.multi-option-image.add-field.preview {
  max-width: 100%;
  /* max-width: 10rem; */
}


.multi-option-image {
  max-width: 20rem;
}

.multi-option-banner-image {
  max-width: 100%;
}

.modal{
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content{
  background-color: #fff;
  width: 100%;
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto;
  padding: 2em;
  border-radius: 3px;
  position: relative;
  animation: 0.3s pop linear;
}
.modal-content.confirmation{
  width: 40%;

}
.modal-content.add-field{
  width: 50%;

}
.modal-content.submission{
  width: 50%;

}
.modal-content.preview{
  max-width: 40%;
  max-height: 80%;
  height: 80%;
}
.modal-content.preview  > * > .MuiInput-input
.modal-content.preview input{
  pointer-events: none;
}

.modal-content.preview .grey-container{
  margin-bottom: 0;
  background-color: transparent;
}
@keyframes pop{
  0%{
    transform: scale(0);
    opacity: 0;
  }
  100%{
    transform: scale(1);
    opacity: 1;
  }
}
.modal-content .close{
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  color: var(--clr-red);
  cursor: pointer;
}


.heading{
  font-size: 2.5em;
  text-transform: capitalize;
}


.navbar-wrapper {
  padding-bottom: 5em;
  z-index: 99;
}

.container{
  --container-px: 15vw;
  padding: 0 var(--container-px);
}
.container.main{
  margin-bottom: 10em;

  padding: 3vw var(--container-px);
}


.navbar{
  background-color: var(--navbar);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  box-shadow: 0 2px 5px #b8b8b8;
  left: 0;
  right: 0;
  position: fixed;
}
.navbar .brand{
  color: #fff;
  font-weight: bold;
  font-size: 1.2em;
}
.navbar .nav span a, .navbar .nav span span{
  color: #fff;
  margin: 0 2vw;
  opacity: 0.9;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8em;
  cursor: pointer;
}
.navbar .nav a:hover, .navbar .nav span:hover{
  opacity: 1;
}


.section{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 3em;
}
.section img, .section .content{
  width: 50%;
}
.section h1{
  font-size: 3em;
}
.section h1 span{
  color: var(--primary-color);
}
.section p{
  margin: 2vw 0;
  color: rgb(122, 122, 122);
  letter-spacing: 1px;
}
.section p .li{
  border-left: 5px solid var(--primary-color);
  padding: 3px 0 3px 5px;
  display: block;
  margin-bottom: 1vw;
}
.section a{
  font-size: .8em;
}


.form{
  margin: 2em 0;
}


.grey-container{
  padding: 2em;
  background-color: rgba(0, 0, 0, 0.05);
}
.add-field-container p{
  font-size: 1.2em;
  margin-bottom: 1em;
}
.add-field-container .btn{
  margin: 0 0.5em 0.5em 0;
}

.list {
    font-size: 16px;
    letter-spacing: .2px;
    line-height: 24px;
    color: #202124;
    font-weight: 400;
}

.input{
  margin-bottom: 1.5em;
}
.input label, .input input, .input textarea{
  display: block;
}
.input label{
  font-weight: bold;
  margin-bottom: 3px;
}
.input input, .input textarea{
  outline: none;
  width: 100%;
  max-width: 100%;
  /* padding: 10px; */
}
.input textarea{
  min-height: 100px;
}
.input.inline{
  display: flex;
  align-items: center;
}
.input.inline label{
  margin-right: 1em;
}
.input.inline label, .input.inline input{
  display: inline;
  width: auto;
}
.input.block{
  display: block;
  align-items: center;
}
.input.block label{
  margin-right: 1em;
  margin-bottom: 30px;
}
.input.block label, .input.block input{
  display: block;
  width: auto;
}
input.file{
  display: none;
}
.file-field{
  display: flex;
  align-items: center;
}
.file-field ul, li{
  margin-left: 1em;
}
.file-field label{
  margin-right: 1em;
}

.preserve-text {
  white-space: pre-wrap;
}

.cards-container{
  margin-top: 2em;
  display: grid;
  gap: 1em;
  grid-template-columns: repeat( auto-fill, minmax(300px, 1fr) );
}
.cards-container.submissions{
  grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
}

.intro {
  left: 0;
  right: 0;
  top: 1%;
  bottom: 0;
  margin: auto;
  width: 50%;
  position: absolute;
}

.intro img {
  width: 100%;
}

.card{
  margin-top: 12px;
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 24px;
  padding-top: 22px;
  position: relative;
}
.card.render{
  -webkit-transition: background-color 200ms cubic-bezier(0,0,0.2,1);
  transition: background-color 200ms cubic-bezier(0,0,0.2,1);
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 24px;
  page-break-inside: avoid;
  word-wrap: break-word;
}

.card.submission-detail {
  width: 14vw;
}
width: 30vw;
.card .title{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-date{
  font-size: 0.6em;
  font-weight: normal;
  color: #999;
}
.card-nav{
  text-align: right;
  padding-top: 0.5em;
}
.card-nav .nav-item.form-card{
  width: 100%;
  text-align: center;
  margin: 5px;
}

.card-nav .nav-item{
  margin-left: 0.5em;
  background-color: rgba(5, 104, 252, 0.1);
  color: rgba(5, 104, 252, 1);
  padding: 0.5em 1em;
  display: inline-block;
  border-radius: 3px;
  cursor: pointer;
}
.card-nav .nav-item:nth-child(2){
  background-color: rgba(0, 243, 32, 0.1);
  color: rgb(0, 165, 22);
}
.card-nav .nav-item:nth-child(3){
  background-color: rgba(243, 0, 0, 0.1);
  color: rgba(243, 0, 0, 1);
}

.msg{
  text-align: center;
  color:#7e7d7d;
}


.progress-bar{
  width: 100%;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  margin: 0.5em 0;
  border-radius: 3px;
}
.progress-bar .progress{
  height: 100%;
  width: 0;
  background-color: var(--primary-color);
  transition: width 0.2s;
  border-radius: 3px;
}

.footer-wrapper {
  margin-top: 10em;
}

.footer{
  background-color: var(--navbar);
  color: #fff;
  padding-top: max(1.5em, 2vw);
  padding-bottom: max(1.5em, 2vw);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.footer .title{
  color: #fff;
  font-weight: bold;
}
.footer .li{
  margin-top: 0.5em;
  display: block;
  color: #fff;
}
.footer a.li{
  text-decoration: underline;
  letter-spacing: 2px;
}



.text-right{
  text-align: right;
}
.text-center{
  text-align: center;
}

.b{
  font-weight: bold;
}

.p-1{
  padding: 1em;
}

.mt-1{
  margin-top: 1em;
}
.mb-0{
  margin-bottom: 0;
}
.mb-1{
  margin-bottom: 1em;
}
.mb-2{
  margin-bottom: 2em;
}
.mb-2-i{
  margin-bottom: 2em !important;
}
.mr-1{
  margin-right: 1em;
}

.err{
  color: var(--clr-red);
}

.alt{
  color: var(--primary-color);
  display: block;
  margin-bottom: 0.5em;
  text-decoration: underline;
}

.link{
  color: var(--primary-color);
  word-wrap: break-word;
}

.spinner{
  display: inline-block;
  height: 20px;
  width: 20px;
  border: 5px solid var(--primary-color);
  border-left: 5px solid transparent;
  border-radius: 50%;
  animation: 0.5s spin linear infinite;
}
.spinner.white{
  border: 5px solid #fff;
  border-left: 5px solid transparent;
}
.spinner.red{
  border: 5px solid rgba(243, 0, 0, 1);
  border-left: 5px solid transparent;
}
@keyframes spin {
  0%{
    transform: rotate(0);
  }
  100%{
    transform: rotate(360deg);
  }
}

.heading-wrapper.page-header {
  padding-top: 40px;
}

.heading-wrapper {
  margin-top: 12px;
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 8px;
  margin-bottom: 12px;
  padding-top: 10px;
  position: relative;
}

.heading-wrapper .head  {
  background-color: rgb(11, 26, 152);
  color: rgba(255, 255, 255, 1);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 10px;
  width: 100%;
  position: absolute;
  top: 0;
}

.heading-wrapper .head.section-header  {
  
    padding: 20px 20px 34px 24px;
    height: 24px;
}
.heading-wrapper .text  {
  padding: 24px;
  margin-top: 30px;
}

.heading-wrapper .title  {
  padding: 24px;
}
.block {
  display: block;
}

.layer {
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.content-layer {
    height: 100%;
}

.form-container {
  width: 65vh;
}

.topBanner-container img {
  width: 65vh;
  border: 1px solid #dadce0;
  border-radius: 8px;
  margin-top: 12px;
}
.space.right {
  margin-right: 10px;
}
.space.left {
  margin-left: 10px;
}


.card-shadow {
  -webkit-transition: background-color 200ms cubic-bezier(0,0,0.2,1);
    transition: background-color 200ms cubic-bezier(0,0,0.2,1);
    background-color: #fff;
    border: 1px solid #dadce0;
    border-radius: 8px;
    margin-bottom: 12px;
    padding: 24px;
    page-break-inside: avoid;
    word-wrap: break-word;
}

.multioption-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-flex-direction: column;
  flex-direction: column;

}

.multioption-row {
  display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    box-pack: justify;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    width: 100%;
}

.multioption-row-wrapper {
  display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    box-pack: justify;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    width: 100%;
}

.multioption-container .multioption-item-wrapper {
  display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    min-height: 24px;
    padding: 0.5em 0.5em 0.5em 0;

}



.multioption-container .multioption-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  max-width: 268px;
  min-height: 24px;
  padding: 0.5em 0.5em 0.5em 0;
  padding-bottom: .5em;
  box-pack: justify;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 268px;
}


.multioption-container .multioption-item .image-container {
  width: 268px;
}

.multioption-container .multioption-item .image-container img {
  max-width:100%;
  max-height:100%;
}

.multioption-container .multioption-item .option-container {
  display: inline-block;
}

.multioption-container .multioption-item .image-container.selected{
  border-style: solid;
  border-width: 2px;
  padding: 6px;
  border-color: rgb(11, 26, 152);
}

.multioption-container .multioption-item .multioption-item-container {
  margin: 0 auto;
}

.edit-mode {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.edit-mode.reverse {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.edit-mode .edit-mode-controls {
  padding-top: 0.5vh;
  display: block;
  width: 5%;
  align-items: flex-end;
}

.edit-mode .edit-mode-preview {
  display: block;
  width: 95%;
  align-items: flex-start;
}

.page-controls {
  line-height: 50px;
}


.page-navigation .btn {
  
  margin-right: 1em;
}


.page-navigation {
  width: 40%;
  position: relative;
  float: left;
}

.page-progress {
  width: 30%;
  position: relative;
  float: right;
  padding-top: 20px;
}

.page-stats {
  display: block;
  width: 30%;
  position: relative;
  float: right;
  text-align: right;
}

@media(min-width: 1021px){

  .card.submission-detail {
    width: 20em;
  }

  .form-container {
    width: 40em;
  }
  
  .topBanner-container img {
    width: 40em;
  }

  .page-progress {
    margin: 0px !important;
  }

  .container{
    --container-px: 10vw;
  }

  .container.main{
    padding: unset;
    margin: 0 auto;
    width: 40em;
  }
}


@media(max-width: 1020px){
  .modal-content.submission {
    width: 80%;
  }

  
  .card.submission-detail {
    width: 33vw;
  }

  .form-container {
    width: 50vw;
  }
  
  .topBanner-container img {
    width: 50vw;
  }

  .page-progress {
    margin: 0px !important;
  }

  .container{
    --container-px: 10vw;
  }

  .container.main{
    padding: unset;
    margin: 0 auto;
    width: 50vw;
  }
}

@media(max-width: 980px){
  .intro {
    width: 90%;
  }
  
  .modal-content.submission {
    width: 90%;
  }


  .card.submission-detail {
    width: 44vw;
  }

   .multi-option-image.create-form {
    max-width: 12rem;
  }
  
  .create-form {
    width: 89vw;
  }

  .modal-content.add-field {
    width: 85%;
  }

  .heading-wrapper.preview {
    width: 65vw;
  }
  .form-container {
    width: 85vw;
  }
  
  .topBanner-container img {
    width: 85vw;
  }

  .page-progress {
    margin: 0px !important;
  }

  .container{
    --container-px: 10vw;
  }
  .container.main{
    padding: unset;
    margin: 0 auto;
    width: 85vw;
  }
}

@media(max-width: 767px){
  .intro {
    width: 90%;
  }

  .modal-content.submission {
    width: 90%;
  }


  .card.submission-detail {
    width: 42vw;
  }

  .page-navigation .btn {
    font-size: 13px;
    margin-right: 1em;
  }

  .page-navigation {
    padding-top: 2.5rem;
    width: 60%;
  }

  .page-progress {
    width: 33rem;
    position: absolute;
    padding-left: 2.5rem;
  }
  
  .page-stats {
    padding-top: 2.5rem;
    width: 40%;
    font-size: 13px;
    text-align: right;

  }

  .multi-option-image.create-form {
    max-width: 12rem;
  }
  
  .create-form {
    width: 20rem;
  }

  .modal-content.add-field {
    width: 85%;
  }

  .heading-wrapper.preview {
    width: 65vw;
  }

  .container{
    --container-px: 6vw;
  }
  .section img, .section .content{
    width: 100%;
  }
  .section .content{
    text-align: center;
  }
  .footer{
    grid-template-columns: 1fr 1fr;
  }

  .form-container {
    width: 80vw;
  }
  
  .topBanner-container img {
    width: 80vw;
  }

  .container.main{
    padding: unset;
    margin: 0 auto;
    width: 80vw;
  }
}
@media(max-width: 640px){
  .intro {
    width: 90%;
  }

  .modal-content.submission {
    width: 90%;
  }

  .card.submission-detail {
    width: 70vw;
  }

  .page-navigation .btn {
    font-size: 13px;
    margin-right: 1em;
  }

  .page-navigation {
    padding-top: 2.5rem;
    width: 60%;
  }

  .page-progress {
    width: 25rem;
    position: absolute;
    padding-left: 2.5rem;
  }
  
  .page-stats {
    padding-top: 2.5rem;
    width: 40%;
    font-size: 13px;
    text-align: right;

  }

  .multi-option-image.create-form {
    max-width: 12rem;
  }

  .create-form {
    width: 80vw;
  }

  .modal-content.add-field {
    width: 85%;
  }

  .heading-wrapper.preview {
    width: 65vw;
  }
  
  .form-container {
    width: 80vw;
  }
  
  .topBanner-container img {
    width: 80vw;
  }
  .btn {
    padding: 0.5em 1em;
  }

  .container.main{
    padding: unset;
    margin: 0 auto;
    width: 80vw;
  }
}


@media(max-width: 450px){

  .intro {
    width: 90%;
  }

  .modal-content.submission {
    width: 90%;
  }
  
  .card.submission-detail {
    width: 70vw;
  }

  .page-navigation {
    padding-top: 2.5rem;
    width: 60%;
  }

  .page-progress {
    width: 60%;
    position: absolute;
    padding-left: 2.5rem;
  }
  
  .page-stats {
    padding-top: 2.5rem;
    width: 40%;
  }

  .multi-option-image.create-form {
    max-width: 12rem;
  }

  .create-form {
    width: 20rem;
  }

  .modal-content.add-field {
    width: 85%;
  }

  .heading-wrapper.preview {
    width: 65vw;
  }

  .heading-wrapper .head.section-header  {
    padding: 20px 20px 45px 24px;
  }

  .form-container {
    width: 76vw;
  }
  
  .heading-wrapper {
    width: 76vw;
  }

  .topBanner-container img {
    width: 76vw;
  }

  .container.main{
    padding: unset;
    margin: 0 auto ;
    width: 76vw;
  }

  .btn {
    padding: 0.5em 1em;
  }

  .container{
    --container-px: 3vw;
  }
  .footer{
    grid-template-columns: 1fr;
  }
  .footer > div{
    margin-top: 1em;
    text-align: center;
  }
  .footer > div:nth-child(1){
    margin-top: 0;
    margin-bottom: 1em;
  }
}

@media(max-width: 400px){
  .intro {
    width: 90%;
  }

  .modal-content.submission {
    width: 90%;
  }

  .card.submission-detail {
    width: 76vw;
  }
  
  .page-navigation .btn {
    font-size: 15px;
    margin-right: 1em;
  }

  .page-navigation {
    padding-top: 2.5rem;
    width: 60%;
  }

  .page-progress {
    width: 19rem;
    position: absolute;
    padding-left: 2.5rem;
  }
  
  .page-stats {
    padding-top: 2.5rem;
    width: 40%;
    font-size: 15px;

  }

  .multi-option-image.create-form {
    max-width: 12rem;
  }
  
  .create-form {
    width: 19rem;
  }


  .modal-content.add-field {
    width: 85%;
  }

  .heading-wrapper.preview {
    width: 65vw;
  }


  .heading-wrapper {
    width: 95vw;
  }

  .form-container {
    width: 95vw;
  }
  
  .topBanner-container img {
    width: 95vw;
  }

  .multioption-container .multioption-item .image-container {
    width: 100%;
  }

  .multi-option-image {
    max-width: 10rem;
  }

  .container.main{
    padding: unset;
    margin: 0 auto;
    width: 40vh;
  }

  
  .btn {
    font-size: 10px;
  }

  .page-info {
    justify-content: flex-start;
    flex-basis: 20% !important;
  }
}

@media(max-width: 300px){

  .intro {
    width: 90%;
  }

  .modal-content.submission {
    width: 90%;
  }

  .card.submission-detail {
    width: 87vw;
  }

  .page-navigation .btn {
    font-size: 13px;
    margin-right: 1em;
  }

  .page-navigation {
    padding-top: 2.5rem;
    width: 60%;
  }

  .page-progress {
    width: 13rem;
    position: absolute;
    padding-left: 2.5rem;
  }
  
  .page-stats {
    padding-top: 2.5rem;
    width: 40%;
    font-size: 13px;
    text-align: left;

  }

  .heading-wrapper {
    width: 90vw;
  }

  .page-progress {
    margin: 0px !important;
  }

  .form-container {
    width: 90vw;
  }
  
  .topBanner-container img {
    width: 90vw;
  }

  .multioption-container .multioption-item .image-container {
    width: 100%;
  }

  .multi-option-image {
    max-width: 10rem;
  }
  .container.main{
    padding: unset;
    margin: 0 auto;
    width: 40vh;
  }
  .container{
    --container-px: 2vw;
  }

  
  .btn {
    font-size: 10px;
  }


  .layer {
    padding-left: 3em;
  }
}
